<template>
  <div>
    <div class="top-bar row p-3 pt-2">
      <div class="col-12 text-center">
        <a href="" class="float-left pt-1 text-white">
          <i
            class="fas fa-chevron-left "
            @click.prevent="$router.push('/pengaturan')"
          ></i>
        </a>
        <h1>Pengaturan Profile</h1>
      </div>
    </div>

    <div class="col-12 col-md-6 text-left mt-3 mb-3">
      <h1><b>Edit</b></h1>
      <div class="">
        <form @submit.prevent="putRegristasis()">
          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1">Nama Depan *</label>
            <input
              required
              type=""
              class="form-control"
              aria-describedby=""
              placeholder="Nama Depan"
              v-model="profileUser.namaDepan"
            />
          </div>
          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1"
              >Nama Belakang *</label
            >
            <input
              required
              type=""
              class="form-control"
              aria-describedby=""
              placeholder="Nama Belakang"
              v-model="profileUser.namaBelakang"
            />
          </div>

          <div class="form-group">
            <label class="" for="exampleFormControlSelect1">kategoris *</label>
            <select class="form-control" v-model="profileUser.kategoriId">
              <option
                v-for="kate in kategories"
                :key="kate.id"
                :value="kate.id"
                >{{ kate.nama }}</option
              >
            </select>
          </div>

          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1">Telepon *</label>
            <input
              required
              type=""
              class="form-control"
              id="exampleInputEmail2"
              aria-describedby=""
              placeholder="Nomer Telepon"
              v-model="profileUser.telepon[0].nomor"
            />
          </div>
          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1">Email *</label>
            <input
              required
              type="email"
              class="form-control"
              id="exampleInputEmail2"
              aria-describedby="emailHelp"
              placeholder="Email"
              v-model="profileUser.email"
            />
          </div>
          <div class="form-group">
            <label class="" for="exampleFormControlSelect1"
              >Tipe Alamat *</label
            >
            <select
              class="form-control"
              placeholder="Tipe alamat"
              v-model="profileUser.alamat[0].tipe"
            >
              <option value="KLINIK">klinik</option>
              <option value="KERJA">Kantor</option>
              <option value="RUMAH">Rumah</option>
              <option value="PRAKTEK_PRIBADI">Praktek Pribadi</option>
              <option value="RUMAH_BERSALIN">Rumah Bersalin</option>
              <option value="RUMAH_SAKIT">Rumah Sakit</option>
            </select>
          </div>
          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1"
              >Nama Alamat *</label
            >
            <input
              required
              type=""
              class="form-control"
              id="exampleInputEmail2"
              aria-describedby=""
              placeholder="alamat"
              v-model="profileUser.alamat[0].nama"
            />
          </div>
          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1">Nama Jalan *</label>
            <input
              required
              type=""
              class="form-control"
              id="exampleInputEmail2"
              aria-describedby=""
              placeholder="Jalan"
              v-model="profileUser.alamat[0].jalan"
            />
          </div>

          <div class="form-group mt-3">
            <label class="" for="exampleFormControlSelect1">UserName *</label>
            <input
              required
              type=""
              class="form-control"
              id="exampleInputEmail2"
              aria-describedby="emailHelp"
              placeholder="UserName"
              v-model="profileUser.userName"
              pattern="^[_.@a-z0-9-]*$"
            />
            <small class="form-text ">{{ getWarn }}</small>
          </div>
          <small class="form-text ">*Pastikan Semua Kolom Terisi</small>
          <button
            type="submit"
            class="btn btn-secondary mt-3 mr-3"
            @click.prevent="$router.push('/pengaturan')"
          >
            <h6 class="mb-0">
              Batalkan
            </h6>
          </button>
          <button
            type="submit"
            class="btn btn-primary mt-3 "
            :disabled="isDisabled"
          >
            <div
              class="spinner-border spinner-border-sm text-primary"
              role="status"
              v-if="loading"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <h6 class="mb-0" v-if="!loading">
              Simpan
            </h6>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/axios";
export default {
  name: "profileEdit",
  data() {
    return {
      kategori: "",
      email: "",
      namaDepan: "",
      namaBelakang: "",
      password: "",
      konfirmasiPassword: "",
      persetujuanEula: true,
      telepon: "",
      tipeAlamat: "",
      alamat: "",
      jalan: "",
      kategories: [],
      loading: false,
      isDisabled: false,
    };
  },
  computed: {
    ...mapState({
      profileUser: (state) => state.profileUser,
    }),
    getWarn() {
      let reg = /^[_.@a-z0-9-]*$/.test(this.profileUser.userName);
      if (this.profileUser.userName.length < 3) {
        this.isDisabled = true;
        return "UserName harus lebih dari 3 huruf";
      } else if (!reg) {
        this.isDisabled = true;
        return "Inputan ini hanya boleh mengandung huruf lowerCase, angka dan karakter _(underscore) .(titik) @(at) -(strip).";
      }
      this.isDisabled = false;
      return "";
    },
  },
  methods: {
    getKategories() {
      axios
        .get(`/api/kategoris`)
        .then(({ data }) => {
          this.kategories = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putRegristasis() {
      let token = localStorage.getItem("token");
      this.profileUser.persetujuanEula = true;
      (this.profileUser.password = "dummypassword"),
        (this.profileUser.konfirmasiPassword = "dummypassword");

      this.loading = true;

      axios
        .put(`/api/registrasis`, this.profileUser)
        .then(({ data }) => {
          this.$toast.success("Profile Berhasil Diubah");
          this.loading = false;
          this.$router.push("/pengaturan");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getKategories();

    $("input[required], select[required]").attr(
      "oninvalid",
      "this.setCustomValidity('Kolom Wajib Diisi')"
    );
    $("input[required], select[required]").attr(
      "oninput",
      "setCustomValidity('')"
    );
  },
};
</script>

<style></style>
